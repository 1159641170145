// Import Bouncer
import Bouncer from "formbouncerjs";
// Import Filepond
import * as FilePond from "filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";

import "filepond/dist/filepond.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";

// Register the plugin
FilePond.registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateSize,
  FilePondPluginImageEdit
);

export const page = async () => {
  // When Tapit has loaded, It will be executed
  document.addEventListener(
    "tapitLoad",
    function (evt) {
      // console.log("yeap start 2");
      console.clear();
      if (evt.detail.isLogout) {
        window.initSession();
      } else if (drupalSettings.user.uid) {
        dataLayerLogin(evt.detail.user); //dataLayer Login
      }
      // console.log(evt.detail);
      // console.log("yeap end 2");
    },
    false
  );

  // Filepond
  setTimeout(() => {
    const filePondEl = document.querySelector(".filepond");

    filePondEl &&
      FilePond.create(filePondEl, {
        name: "image",
        labelIdle:
          '<img src="https://coaguila.s3.us-east-2.amazonaws.com/aguila-serie/assets/download.svg"> <br> Sube tu foto haciendo clic.',
        storeAsFile: true,
      });
  }, 100);

  // Postulation form
  const formBox = document.querySelectorAll(".formBox");
  formBox.length > 0 && validateForm(formBox);

  //dataLayer Registro Formulario
  document
    .querySelector("#edit-submit")
    .addEventListener("click", dataLayerRegisterStory);
};

// Validate Form
function validateForm() {
  new Bouncer(".formBox", {
    patterns: {
      url: /^(?:(?:https?|HTTPS?|ftp|FTP):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/,
    },
    messages: {
      missingValue: {
        checkbox:
          "Debe aceptar términos y condiciones y políticas de protección de datos personales",
        radio: "Selecciona una opción",
        select: "Selecciona una opción",
        default: "Este campo es obligatorio",
      },
    },
  });
}

//dataLayer login

function dataLayerLogin(userData) {
  const email = userData.email;
  const id = drupalSettings.user.uid;
  let hash = CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex);
  window.localStorage.setItem("emailSerieFutbol", hash);

  dataLayer.push({
    event: "login",
    medium: "Tapit",
    campaign: "aguila-serie-web",
    userID: hash,
  });
}

function dataLayerRegisterStory() {
  dataLayer.push({
    event: "trackEvent",
    eventCategory: "Aguila Serie Web", // Categoría del evento (String). Requerido.
    eventAction: "Registro", // Acción o subcategoría del evento (String). Requerido.
    eventLabel: window.localStorage.getItem("emailSerieFutbol"), // Etiqueta de descripción del evento (String). Requerido.
  });
}
