import { loadParallax } from "./oasis";

export const page = async () => {
  setFloatImages()
  loadParallax()
}

function setFloatImages() {
  let markupHtml =
    `<div class="float-images float-images--recovery">
    <div class="float-images__desktop">
        <img src="${drupalSettings.path.themeUrl}/sources/dist/img/oasis/bottles.png" class="bottles rellax" data-rellax-speed="-0.6">
        <img src="${drupalSettings.path.themeUrl}/sources/dist/img/oasis/flor.png" class="flor rellax" data-rellax-speed="0.8">
        <img src="${drupalSettings.path.themeUrl}/sources/dist/img/oasis/flor-am.png" class="flor-am rellax" data-rellax-speed="0.8">
        <img src="${drupalSettings.path.themeUrl}/sources/dist/img/oasis/flor-am-small.png" class="flor-am--small rellax" data-rellax-speed="-0.6">
        <img src="${drupalSettings.path.themeUrl}/sources/dist/img/oasis/palma-azul.png" class="palma-azul rellax" data-rellax-speed="-0.4">
        <img src="${drupalSettings.path.themeUrl}/sources/dist/img/oasis/palma.png" class="palma rellax" data-rellax-speed="0.4">
				<img src="${drupalSettings.path.themeUrl}/sources/dist/img/oasis/palma-amarilla.png" class="palma-amarilla rellax" data-rellax-speed="-0.4">
      </div>
    </div>`

    let refContainer = document.getElementById('block-aguila-content')

    refContainer.insertAdjacentHTML('afterbegin', markupHtml)
}