/* MODAL */

import { videoBox } from "./videoBox";

// Modal
export const modal = () => {
  const modals = document.querySelectorAll(".modal");
  modals.length > 0 && startModal();
};

// Start Modal
const startModal = () => {
  openModalFromBtn();
  closeModalFromBtn();
};

// Open Modal From Btn
const openModalFromBtn = () => {
  const btnModals = document.querySelectorAll(
    "[data-modal]:not(.modals)"
  );
  btnModals.length > 0 && Array.from(btnModals).map((btnModal) => {
      btnModal.addEventListener("click", (e) => {
        e.preventDefault();
        if (!e.currentTarget.classList.contains("modal")) {
          const dataModal = btnModal.dataset.modal;
          const dataVideo = btnModal.dataset.video;
          dataModal && openModal(dataModal);
          dataVideo && changeVideo(dataVideo);
        }
      });
    });
};

// Open Modal
const openModal = (modalName) => {
  const modalToOpen = document.querySelector(
    `.modal[data-modal="${modalName}"]`
  );
  modalToOpen.classList.add("show");
};

// Close Modal From Btn
const closeModalFromBtn = () => {
  const btnsCloseModal = document.querySelectorAll(".modal__close");
  btnsCloseModal.length > 0 && Array.from(btnsCloseModal).map((btnCloseModal) => {
      btnCloseModal.addEventListener("click", (e) => {
        e.preventDefault();
        e.stopPropagation();
        const modal = btnCloseModal.closest(".modal");
        const dataModal = modal.dataset.modal;
        const videoModal = modal.querySelector(".videoYT iframe");
        dataModal && closeModal(dataModal);
        if (videoModal) videoModal.src = videoModal.src;
      });
    });
};

// Close Modal
const closeModal = (modalName = null) => {
  if (modalName) {
    const modalToClose = document.querySelector(
      `.modal[data-modal="${modalName}"]`
    );
    modalToClose.classList.remove("show");
  } else {
    const modals = document.querySelectorAll(".modal");
    Array.from(modals).map(
      (modal) =>
        !modal.classList.contains("show") &&
        modal.classList.remove("show")
    );
  }
};

// Change Video
const changeVideo = (videoName) => {
  const iframeVideo = document.querySelector(
    '.modal[data-modal="video"].show .videoYT iframe'
  );
  const newSrc = `https://www.youtube.com/embed/${videoName}`;
  if (iframeVideo && newSrc) iframeVideo.src = newSrc;
};
