import {
  win,
  doc,
  log,
  al,
  addEv,
  que,
  queAll,
  gId,
} from "../general/var.js";
import apiDrupal from "../services/apiDrupal.js";
import dama from "../services/dama.js";
// Import Swiper
import Swiper from "swiper";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
import "swiper/swiper-bundle.css";
// Import AOS
import AOS from "aos";
import "aos/dist/aos.css";
// Import Parallax
import { parallax } from "../components/parallax";
// Import Video Box
import { videoBox } from "../components/videoBox";
// Import Float Box
import { floatBox } from "../components/floatBox";
// Import Modal
import { modal } from "../components/modal";

// Configure Swiper to use modules
SwiperCore.use([Navigation, Pagination]);

const dm = new dama();

win.onload = () =>
  doc.que(".cosLoader") &&
  doc.que(".cosLoader").classList.add("hide");
const api = new apiDrupal();

export const page = async () => {
  /* ON LOAD */
  // console.log('General Page')

  // Clear console
  const btn_gamenow_cans = document.getElementById(
    "btn_gamenow_cans"
  );
  if (
    typeof btn_gamenow_cans != "undefined" &&
    btn_gamenow_cans != null
  ) {
    btn_gamenow_cans.addEventListener("click", () => {
      dataLayer.push({
        event: "trackEvent",
        eventCategory: "Juego Aguila Latas",
        eventAction: "Clic",
        eventLabel: "Juega Ahora",
        eventValue: "",
      });
    });
  }

  trivia();

  // AOS
  const aosElements = document.querySelectorAll("[data-aos]");
  aosElements.length > 0 &&
    AOS.init({
      offset: 10,
      once: true,
      duration: 750,
      throttleDelay: 100,
    });

  // Video Box
  videoBox();

  // Float Box
  floatBox()

  // Parallax
  parallax();

  // Modal
  modal();

  // Carousel
  const carousel = new Swiper(".swiper-container", {
    slidesPerView: 3,
    navigation: {
      nextEl: null,
      prevEl: null,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      991: {
        slidesPerView: 3,
        navigation: {
          nextEl: null,
          prevEl: null,
        },
      },
    },
  });
  const carouselSerie = new Swiper('.boxSerie__banner', {
    loop: true,
    orientation: 'horizontal',
    slidesPerView: 1,
  
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });
  const carouselSerieFans = new Swiper('.boxSerie__fans--rightCarousel', {
    slidesPerView: 1, 
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      991:{
        slidesPerView: 3,
        spaceBetween: 40,        
      }
    },
    
  });
};

function trivia() {
  const request = dm.getParamUrl("respuesta");
  dm.hide(["trivia-answers", "trivia-request"]);
  dm.hideShow(
    request ? "trivia-answers" : "trivia-request",
    request ? "trivia-request" : "trivia-answers"
  );
}

export default page;

//Viejoven
jQuery(".owl-viejoven").owlCarousel({
  loop: true,
  margin: 10,
  nav: true,
  autoplay: true,
  autoplayTimeout: 5000,
  autoplayHoverPause: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
});
