/* FLOAT BOX */

// Float Box
export const floatBox = () => {
  const floatBoxes = document.querySelectorAll(".floatBox");
  floatBoxes.length > 0 && startFloatBox();
};

// Start Float Box
const startFloatBox = () => {
  openFloatBoxFromBtn();
  closeFloatBoxFromBtn();
};

// Open Float From Btn
const openFloatBoxFromBtn = () => {
  const btnsFloatBox = document.querySelectorAll('[data-floatbox]:not(.floatBox)')

  btnsFloatBox.length > 0 && Array.from(btnsFloatBox).map( btnFloatBox => {
    btnFloatBox.addEventListener('click', e => {
      e.preventDefault()
      if( !e.currentTarget.classList.contains('floatBox') ) {
        const dataFloatBox = btnFloatBox.dataset.floatbox;
        dataFloatBox && openFloatBox(dataFloatBox);
      }
    })
  } )
};

// Open FloatBox
const openFloatBox = (floatBoxName) => {
  const floatBoxToOpen = document.querySelector(`.floatBox[data-floatbox="${floatBoxName}"]`);
  floatBoxToOpen.classList.remove("hide");
};

// Close Modal From Btn
const closeFloatBoxFromBtn = (floatBoxName) => {
  const btnsCloseFloatBox = document.querySelectorAll('.floatBox__close')

  btnsCloseFloatBox.length > 0 && Array.from(btnsCloseFloatBox).map( btnCloseFloatBox => {
    btnCloseFloatBox.addEventListener('click', e => {
      e.preventDefault()
      e.stopPropagation()
      const floatBox = btnCloseFloatBox.closest(".floatBox");
      const dataFloatBox = floatBox.dataset.floatbox
      dataFloatBox && closeFloatBox(dataFloatBox);
    })
  })
};

// Close Float Box
export const closeFloatBox = (floatBoxName = null) => {
  if( floatBoxName ) {
    const floatBoxToClose = document.querySelector(`.floatBox[data-floatbox="${floatBoxName}"]`);
    floatBoxToClose.classList.add("hide");
  } else {
    const floatBoxes = document.querySelectorAll(".floatBox");
    Array.from(floatBoxes).map( (floatBox) => !floatBox.classList.contains("hide") &&  floatBox.classList.add("hide") );
  }
};
