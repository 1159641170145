import apiDrupal from "../services/apiDrupal.js";
import dama from "../services/dama.js";

const api = new apiDrupal();
const dm = new dama();
let dataApi = {
    answers: '',
    request: ''
}
let rootElement = document.documentElement
export const page = async () => {
  const init = async () => {
    const request = await api.index()
    dm.click('tryAgain', tryAgain)
  }
  init()
}
function tryAgain(){
    location.reload()
}
function scrollToTop() {
    rootElement.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

window.triviaSubmit = (e) => {
    e.preventDefault();
    //CAPTURAMOS LA DATA
    let dataForm = document.querySelectorAll("#trivia-submit input:checked");
    let data = getvalues(dataForm);
    let answers = correctAnswer(data)
    let correctAnswerUser = totalCorrectAnswerUser(data, answers);
    dataApi.request = document.getElementById(`message-${correctAnswerUser}`).textContent
    
    api.sendDataTrivia(dataApi)
    dm.removeClass(`message-${correctAnswerUser}`, 'hidden')
    dm.hideShow("trivia-answers", "trivia-request")
    scrollToTop()
    return false;
}

function totalCorrectAnswerUser(data, answers){
    let correctAnswerUser = 0;
    data.map( userAnswer => {
        let answerArray = answers[userAnswer.id]
        let findAnswer = answerArray.find( value => value == parseInt(userAnswer.select)+1)
        if(findAnswer){
            correctAnswerUser++
        }
    })
    return correctAnswerUser;
}
function totalResponse(){
    return document.getElementsByClassName('trivia-answer').length;
}
function correctAnswer(data){
    let answer = {}
    data.map( value => {
        let correctAnswer = document.querySelectorAll("[object='" + value.id + "']");
        answer[value.id] = []
        correctAnswer.forEach(element => {
            answer[value.id].push(element.getAttribute('req'))
        });
    })
    return answer;
}
function getvalues(dataForm){
    let data = [];
    dataForm.forEach((input) => {
        const answer = input.value.split("-");
        dataApi.answers =  dataApi.answers+answer[1]+', '
        let request = {
            'id': input.name,
            'select': answer[0],
            'value': answer[1]
        }
        data.push(request);
    });
    return data;
}