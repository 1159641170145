import apiDrupal from "../services/apiDrupal.js";
import dama from "../services/dama.js";
import AOS from "aos";
import "aos/dist/aos.css";

export const page = async () => {
  const idbanner = document.querySelector("#banner-viejoven");

  idbanner.addEventListener("click", function (event) {
    dataLayer.push({
      event: "trackEvent",
      eventCategory: "Aguila Viejoven", // Categoría del evento (String). Requerido.
      eventAction: "Clic", // Acción o subcategoría del evento (String). Requerido.
      eventLabel: "Conoce mas", // Etiqueta de descripción del evento (String). Requerido.
    });
  });
}

