import apiDrupal from "../services/apiDrupal.js";
import dama from "../services/dama.js";

const api = new apiDrupal();
const dm = new dama();

export const page = async () => {
  const init = async () => {
    // const zonalata =  document.getElementById("zona-lata");
    // if (typeof(zonalata) != 'undefined' && zonalata != null)
    // {
    //   zonalata.addEventListener("click", () => {

    //     dataLayer.push({
    //       event: "trackEvent",
    //       eventCategory: "Juego Aguila Latas",
    //       eventAction: "Juega Ahora",
    //       eventLabel: CryptoJS.SHA256(drupalSettings.email),
    //       eventValue: ""
    //    });
    //   });
    // }

    const btn_playagain = document.getElementById(
      "btn_goBackToProfile"
    );
    if (
      typeof btn_playagain != "undefined" &&
      btn_playagain != null
    ) {
      btn_playagain.addEventListener("click", () => {
        const hash = CryptoJS.SHA256(drupalSettings.email);
        const texthash = hash.words.toString();

        dataLayer.push({
          event: "trackEvent",
          eventCategory: "Aguila Oasis",
          eventAction: "Ir al perfil",
          eventLabel: texthash,
        });
      });
    }

    const btn_playranking = document.getElementById("goToRanking");
    if (
      typeof btn_playranking != "undefined" &&
      btn_playranking != null
    ) {
      btn_playranking.addEventListener("click", () => {
        const hash = CryptoJS.SHA256(drupalSettings.email);
        const texthash = hash.words.toString();

        dataLayer.push({
          event: "trackEvent",
          eventCategory: "Aguila Oasis",
          eventAction: "Ranking",
          eventLabel: texthash,
        });
      });
    }

    const btn_playgame = document.getElementById("goToPlay");
    if (typeof btn_playgame != "undefined" && btn_playgame != null) {
      btn_playgame.addEventListener("click", () => {
        const hash = CryptoJS.SHA256(drupalSettings.email);
        const texthash = hash.words.toString();

        dataLayer.push({
          event: "trackEvent",
          eventCategory: "Aguila Oasis",
          eventAction: "Jugar",
          eventLabel: texthash,
        });
        location.href = "/entretenimiento/juega-y-gana";
      });
    }

    dataLayerLogin();
  };
  init();
};

function dataLayerLogin() {
  window.addEventListener("DOMContentLoaded", (event) => {
    const btn_initSession = localStorage.getItem("dataLayerLogin");
    if (
      typeof btn_initSession != "undefined" &&
      btn_initSession != null
    ) {
      const hash = CryptoJS.SHA256(drupalSettings.email);
      const texthash = hash.words.toString();
      dataLayer.push({
        event: "trackEvent",
        eventCategory: "Aguila Oasis",
        eventAction: "Iniciar sesion",
        eventLabel: texthash,
      });
      localStorage.removeItem("dataLayerLogin");
    }
  });
}
