import apiDrupal from "../services/apiDrupal.js";
import dama from "../services/dama.js";

const api = new apiDrupal();
const dm = new dama();

let rootElement = document.documentElement;
export const page = async () => {
  //window.onload=palyVideo;
};

/*function palyVideo(){
  var player =  document.getElementById('videoRespuesta');
  player.play();
}*/

// capturar datalayer red social visitada desde resultado
let containerShare = document.querySelector(".containerShare");

containerShare.addEventListener("click", function (event) {
  let socialNetwork = event.target.parentNode.id;
  dataLayer.push({
    event: "trackEvent",
    eventCategory: "Aguila Viejoven", // Categoría del evento (String). Requerido.
    eventAction: "Compartir", // Acción o subcategoría del evento (String). Requerido.
    eventLabel: socialNetwork, // Etiqueta de descripción del evento (String). Requerido.
  });
});

// capturar dataLayer en descargar desde resultado
let containerDownload = document.querySelector(
  ".containerDownload a"
);

containerDownload.addEventListener("click", function (event) {
  let socialNetwork = event.target.parentNode.id;
  dataLayer.push({
    event: "trackEvent",
    eventCategory: "Aguila Viejoven", // Categoría del evento (String). Requerido.
    eventAction: "Clic", // Acción o subcategoría del evento (String). Requerido.
    eventLabel: "Descargar", // Etiqueta de descripción del evento (String). Requerido.
  });
});
