export const page = async () => {
  //dataLayer Video destacado

  const btnVideoDestacado = document.querySelector(
    "#btn-video-destacado"
  );

  btnVideoDestacado && btnVideoDestacado.addEventListener("click", (e) => {
    let titleVideoDestacado = document
      .querySelector("#title-video-destacado")
      .textContent.trim();
    dataLayer.push({
      event: "trackEvent",
      eventCategory: "Aguila Serie Web", // Categoría del evento (String). Requerido.
      eventAction: "Play", // Acción o subcategoría del evento (String). Requerido.
      eventLabel: titleVideoDestacado, // Etiqueta de descripción del evento (String). Requerido.
    });
  });
};
