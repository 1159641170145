export const routes = [
  {
    name: "aguilaserie",
    path: "/historia-de-una-pasion",
    component: "aguilaserie",
  },
  {
    name: "postulation",
    path: "/historia-de-una-pasion/postula-tu-historia",
    component: "postulation",
  },
  {
    name: "AguilaTrivia",
    path: "/aguila-light-en-vivo/trivia",
    component: "AguilaTrivia",
  },
  {
    name: "Login",
    path: "/iniciar-sesion",
    component: "login",
  },
  {
    name: "Game",
    path: "/entretenimiento/juegos/usuario",
    component: "game",
  },
  {
    name: "Ranking",
    path: "/entretenimiento/juegos/oasis/ranking",
    component: "game",
  },
  {
    name: "JugarAqui",
    path: "/entretenimiento/juega-y-gana",
    component: "game",
  },
  {
    name: "Trivia-Viejoven",
    path: "/aguilacero/registro",
    component: "ViejovenTrivia",
  },
  {
    name: "Viejoven",
    path: "/aguilacero",
    component: "Viejoven-landing",
  },

  {
    name: "respuesta",
    path: "/aguilacero/resultado",
    component: "resultado",
  },
  {
    name: "home",
    path: "/",
    component: "home",
  },
  // OASIS
  {
    name: "oasis",
    path: "/aguila-oasis",
    component: "oasis",
  },
  {
    name: "oasis",
    path: "/nuestras-cervezas",
    component: "oasis",
  },
  {
    name: "recovery",
    path: "/user/password",
    component: "recovery",
  },
  {
    name: "legals",
    path: "/abilegal/politica-deproteccion-de-datos-personales",
    component: "legals",
  },
];
