import { win, doc } from "../general/var.js";

/**
 *
 * Service Dama JS
 *
 *
 */

class dama {
  click(name = null, configuration) {
    const element = document.querySelectorAll(
      "[dm-click=" + name + "]"
    )[0];
    element &&
      element.addEventListener("click", configuration, false);
    return "render";
  }
  hide(ids = []) {
    ids.map((id) => {
      const element = document.getElementById(id);
      if (element) element.style.display = "none";
    });
    return "render";
  }
  show(id = null) {
    const element = document.getElementById(id);
    if (element) element.style.display = "block";
    return "render";
  }
  removeClass(id = null, elementClass = null) {
    const element = document.getElementById(id);
    if (element) element.classList.remove(elementClass);
    return "render";
  }
  hideShow(idHide = null, idShow = null) {
    let element = document.getElementById(idHide);
    if (element)
      element.setAttribute("style", "display:none !important");
    element = document.getElementById(idShow);
    if (element)
      element.setAttribute("style", "display:block !important");
    return "render";
  }

  printOneTagHtml(name, html){
    const elements = document.querySelectorAll('[dm-model=' + name + ']')
    elements.forEach( element => element.innerHTML = html);
    return
  }

  print(name, text) {
    const element = document.querySelectorAll('[dm-model=' + name + ']')[0]
    if (typeof element != "undefined" && element != null)
      element.innerHTML = text;
    return;
  }

  renderPage(data){
    const keys = Object.keys(data);
    keys.map( key =>  this.printOneTagHtml(key, data[key]))
}

  getParamUrl(param) {
    const url_string = window.location.href
    const url = new URL(url_string);
    const getParam = url.searchParams.get(param);
    return getParam;
  }
  
  loader() {
    const loader = ` 
        <div class="cosLoader">
            <div class="cosLoader__container">
                <svg class="cosLoader__content" viewBox="25 25 50 50">
                    <circle class="cosLoader__circular" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
                </svg>
            </div>
         </div>
        `;
    document.body.innerHTML += loader;
    win.onload = () =>
      doc.que(".cosLoader") &&
      doc.que(".cosLoader").classList.add("hide");
    setTimeout(() => {
      doc.que(".cosLoader").classList.add("hide");
    }, 5000);
  }
}

export default dama;
