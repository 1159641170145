/* VIDEO BOX */

// Video Box
export const videoBox = () => {
  const videoBoxes = document.querySelectorAll(".videoBox");
  videoBoxes.length > 0 &&
    Array.from(videoBoxes).map((videoBox) => startVideoBox(videoBox));
};

// Start video box
function startVideoBox(videoBox) {
  showVideoList(videoBox);
}

// Show video list
function showVideoList(videoBox) {
  const playBtn = videoBox.querySelector(".videoBox__play");
  const videoList = videoBox.querySelector(".videoList");
  const floatBox = videoBox.querySelector(".floatBox");

  playBtn &&
    videoList &&
    playBtn.addEventListener("click", (e) => {
      e.preventDefault();
      videoList.classList.add("show");
      floatBox && floatBox.classList.add("opened");
    });
}
