/* PARALLAX */

// Import GSAP
import { TweenMax } from "gsap";

export const parallax = () => {
  let isMobile = false;

  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
      navigator.userAgent
    )
  )
    isMobile = true;

  window.matchMedia("(max-width: 767px)").matches && isMobile
    ? gyroscopeAnimation()
    : parallaxAnimation();
};

// Animation with Gyroscope
function gyroscopeAnimation() {
  window.DeviceOrientationEvent &&
    window.addEventListener("deviceorientation", (e) => {
      e.stopPropagation();
      e = e || window.e;

      const sideX = Math.min(
        Math.max(parseInt(Math.floor(event.gamma)), -50),
        50
      );
      const sideY = Math.min(
        Math.max(parseInt(Math.floor(event.beta)), -50),
        50
      );
      const newSideX = sideX * 16;
      const newSideY = sideY * 16;
      const percentage = 80;

      moveParallax(newSideX, newSideY, percentage);
    });
}

// Animation Parallax
function parallaxAnimation() {
  const rootElement = document.documentElement;

  rootElement.addEventListener("mousemove", (e) => {
    const windowWidth = window.outerWidth;
    const windowHeight = window.outerHeight;
    const x = e.pageX - rootElement.offsetLeft;
    const y = e.pageY - rootElement.offsetTop;
    const newX = x - windowWidth / 2;
    const newY = y - windowHeight / 2;
    const percentage = 50;

    moveParallax(newX, newY, percentage, true);
  });
}

// Move Parallax
function moveParallax(newX, newY, percentage, isRevert = false) {
  const parallaxItems = document.querySelectorAll(
    ".parallaxItem[data-speed]"
  );

  Array.from(parallaxItems).map((parallaxItem) => {
    let speed = parallaxItem.dataset.speed;
    let posX = newX;
    let posY = newY;

    if (parallaxItem.dataset.revert && isRevert == true) speed *= -1;

    if (percentage > 0) {
      posX = (posX / 100) * percentage;
      posY = (posY / 100) * percentage;
    }

    TweenMax.to(parallaxItem, 1, {
      x: posX * speed,
      y: posY * speed,
    });
  });
}
