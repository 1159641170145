import { routes } from "./routes.js";

let route = routes.find(
  (item) => window.location.pathname == item.path
);

route = route
  ? route
  : routes.find(
      (item) => window.location.pathname == `/${item.name}.html`
    );

// console.log('route', route)

require("./partials/generalPage.js").page();
if (route) {
  require("./components/" + route.component + ".js").page();
}
