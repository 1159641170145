import apiDrupal from "../services/apiDrupal.js";
import dama from "../services/dama.js";
import AOS from "aos";
import "aos/dist/aos.css";

const api = new apiDrupal();
const dm = new dama();

let rootElement = document.documentElement;
export const page = async () => {
  /*const init = async () => {
    const botones = document.querySelector(".paragraph--view-mode--default")
    botones.addEventListener("click", (e) => {
      console.log(e)
    })
  }*/
  //init()
  animates();
  AOS.init();
  h1Hidden();
};

function animates() {
  const elements = document.querySelectorAll(".clear");
  elements.forEach((element) => {
    element.setAttribute("data-aos", "zoom-in-up");
  });
}

function h1Hidden() {
  let hidden = document.querySelector("#block-aguila-page-title");
  hidden.innerHTML =
    '<p class="title"><span class="field--label-hidden">Viejoven</span></p>';
}


const idbanner = document.querySelector("#datalayersubmit");
idbanner.addEventListener("click", function (event) {
  dataLayer.push({
    'event': 'trackEvent',
    'eventCategory': 'Aguila Viejoven', // Categoría del evento (String). Requerido.
    'eventAction': 'Clic', // Acción o subcategoría del evento (String). Requerido.
    'eventLabel': 'Haz clic aqui', // Etiqueta de descripción del evento (String). Requerido.
  });
  location.pathname = '/viejoven/responde-y-gana'
});

const idbanner2 = document.querySelector("#datalayersubmit2");
idbanner2.addEventListener("click", function (event) {
  dataLayer.push({
    'event': 'trackEvent',
    'eventCategory': 'Aguila Viejoven', // Categoría del evento (String). Requerido.
    'eventAction': 'Clic', // Acción o subcategoría del evento (String). Requerido.
    'eventLabel': 'Haz clic aqui', // Etiqueta de descripción del evento (String). Requerido.
  });
  location.pathname = '/viejoven/responde-y-gana'
});
